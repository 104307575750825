html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}

body {
    font-family: var(--serif);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--sans);
    text-wrap: pretty;
}

h1,
.h1 {
    font-size: calc(1.5 * var(--base-font-size));
}

h2,
.h2 {
    font-size: var(--base-font-size);
    font-weight: bold;

    .post-content &,
    .post-content & {
        // margin-top: calc(2 * var(--line));
        margin-bottom: var(--line);
    }

    .post-content hr + &,
    .post-content hr + & {
        margin-top: var(--line);
    }
}

h3,
.h3 {
    font-size: var(--base-font-size);
    font-weight: bold;
    color: var(--secondary-text);
}

h4,
.h4 {
    font-size: 0.75em;
}

p {
    margin-bottom: var(--line);
}

sup {
    line-height: 0;
    font-size: 66%;
}

.site-header,
.site-footer {
    font-family: var(--sans);
    line-height: 1.2;
}

.page-subtitle,
.post-subtitle {
    display: block;
    margin-bottom: var(--line);
}

.site-name {
    font-weight: bold;
    display: block;
}

blockquote {
    font-style: italic;
    border-left: 1px solid var(--underline);
    margin-left: 0;
    padding-inline-start: 2ch;
    padding-top: 0.5ch;
    padding-bottom: 0.5ch;
    margin-bottom: var(--line);

    em {
        font-weight: bold;
        font-style: normal;
    }

    *:first-child {
        margin-top: 0;
    }
    
    *:last-child {
        margin-bottom: 0;
    }

    li & {
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    // &.tweet {
    //     border: 1px solid var(--underline);
    //     padding: 1ch 2ch;
    //     border-radius: 1ch;
    // }
    
}

.post-content > blockquote:first-child {
    margin-top: -0.5ch;
}

cite,
figcaption {
    font-size: 0.75em;
    font-family: var(--sans);
    font-style: normal;
    display: block;
    color: var(--secondary-text);
}

figcaption {
    // text-align: center;
    // max-width: 55ch;
    // width: 100%;
    margin-inline: auto;
    @media (min-width: 35rem) {
    padding-inline: var(--line);
    }
}

.post-title {
    // font-family: var(--serif);
    font-weight: bold;
    font-size: var(--base-font-size);
    font-weight: 900;
    font-feature-settings: lnum;
    text-wrap: balance;
}

.page-title {
    font-size: var(--base-font-size);
    font-feature-settings: lnum;
    text-wrap: balance;
}

.page-header {
    font-family: var(--sans);
}

.post-header {
    @media (min-width: 45rem) {
        position: sticky;
        top: var(--line);
    }
}

.post-date {
    font-family: var(--sans);
    color: var(--secondary-text);
}

code {
    background: black;
    padding: 0.5ch;
    border-radius: 0.5ch;
}

ol,
ul {
    margin-bottom: var(--line);
    padding-left: 1.1em;
}

li::marker {
    color: var(--secondary-text);
}

header,
footer {
    a {
        text-decoration: none;
    }
}

summary *,
summary h3 {
    display: inline-block;
}

summary::marker,
summary::-webkit-details-marker {
    color: var(--underline);
}

.library-list {
    font-size: 1rem;
}

.library-list {
    line-height: 1.2;
}

.library-title {
    font-weight: 900;
}

.meta-text {
    color: var(--secondary-text);
}

mark {
    background: linear-gradient(45deg, rgba(223,252,57,1) 0%, rgba(223,252,57,0.25) 100%)
}

li ul,
li ol {
    margin-bottom: 0;
}

ol, ul {
    hr {
        margin-bottom: 0;
        border-top-style: dotted;
    }
}

a {
    color: inherit;
    text-decoration-color: var(--underline);
    text-decoration-thickness: 1px;
    cursor: pointer;

    &:hover {
        color: var(--primary-color);
        text-decoration-color: currentColor;
    }

    &[href^="http"] {
        &:hover {
            color: #1e69d9;
        }
    }

}

::selection {
    background: var(--secondary-color);
    color: var(--body-color);
}