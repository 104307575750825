#pizza {
    // --primary-color: #D90404;
    --primary-color: var(--secondary-color);
    --body-color: var(--primary-color);
    --peel: calc(25vh + 5vw);
    --sans: Futura-PT, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
    --cursive: "rizado-script", cursive;
    
    // --serif: var(--sans);

    @media (prefers-color-scheme: dark) {
        --background: #320101;
        --background: black;
        --background: hsl(215.9 75.71% 10%);
        --body-color: var(--white);
    }


    main {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: var(--line-2);
    }

    .site-header,
    .site-name,
    .site-footer {
        display: none;
    }

    .ingredients {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-block-end: 1em;

        li {
            margin: 0;
            padding: 1ch 0;
            border-block-end: 1px dotted currentColor;
        }

        small {
            opacity: 0.75;
            font-style: italic;
        }
    }

    .ingredients li:last-of-type {
        border-block-end: 0;
    }

    .ingredients[aria-label]:before {
        content: attr(aria-label);
        font-family: var(--sans);
        font-weight: bold;
        border-block-end: 1px solid;
        width: 100%;
        display: block;
    }

    &:before {
        content: '';
        height: 1rem;
        width: 100%;
        position: fixed;
        overflow: hidden;
        background-image: url(/src/img/checker.svg);
        background-size: contain;
        background-repeat: repeat;
    }

    h1,
    .h1 {
        text-transform: uppercase;
        // letter-spacing: -0.1em;
        font-family: var(--sans);
        line-height: 1;
    }

    .date-title {
        font-size: 5rem;
        font-weight: 700;
        display: block;
        letter-spacing: -0.05em;
    }

    h3 {
        color: inherit;
    }

    .stats-box {
        display: grid;
        grid-gap: 1ch;
        text-align: center;
        text-wrap: balance;
        grid-template-columns: repeat(1, 1fr);
        @media (min-width: 480px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .stat-feature {
        display: block;
        font-size: 3em;
        letter-spacing: -0.03em;
        font-family: var(--sans);
        font-weight: 400;
    }

    .homegrown {
        border: 1px solid;
        padding: 0.5ch 1ch;
        border-radius: 50%;
        font-style: normal;
        font-size: 60%;
        text-transform: uppercase;
        font-family: var(--sans);
        font-style: italic;
    }

    main {
        margin-block-start: var(--peel);
        padding: var(--line-2) 0;
    }

    main .wrap {
        display: flex;
        flex-direction: column;
        gap: var(--line-2);

        @media (min-width: 720px) {
            margin-inline: auto;
            width: 50ch;
        }
    }

    .peel {
        margin-inline: auto; 
        position: absolute; 
        top: 0; 
        left: 0; 
        right: 0;
        height: var(--peel);
    }

    .h1 {
        font-weight: 600;
    }

}

.slogan {
    text-align: center;
    font-size: 3em;
    // letter-spacing: -0.02em;
    font-family: var(--cursive);
    line-height: 1;
    text-wrap: balance;
    max-width: 90vw;
}

a:hover {
    text-decoration-style: wavy;
    text-decoration-skip-ink: none;
}

.arc-curve {
    fill: transparent;
}

.arc-text {
    text-align: center;
    text-transform: uppercase;
    font-family: var(--sans);
    fill: currentColor;
}

.arc {
    width: 100%;
    max-width: 12rem;
    color: currentColor;
    margin-inline: auto;
    margin-block-end: -1em;
}

.next-party {
    padding: 1.5em;
    text-align: center;
    font-family: var(--sans);
    border: 4px double;
    border-radius: 100%;
    margin-inline: auto;
    margin-top: 2em;
    transform: rotate(10deg);
    display: inline-block;
    transition-duration: 0.5s;
    &:hover {
        transform: rotate(0deg);
    }
}

.pizzas {
    width: 100%;
    padding-inline: var(--line);
    max-width: 100%;

    > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        gap: var(--line);
        @media (min-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (min-width: 1000px) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
.pizza-item {
    text-align: center;
    h3 {
        color: inherit;
        font-family: var(--cursive);
        font-size: 1.25em;
        line-height: 1;
        padding-block-end: 1ch;
    }
    border: 3px double;
    padding: var(--line);
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    li {
        border-block-start: 1px dotted;
    }
}