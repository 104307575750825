*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

// Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

// Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

// Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;

}

// Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

// Make images easier to work with */
img,
picture,
video,
canvas,
svg {
  max-width: 100%;
  display: block;
}

menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

// Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
      scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
  }
}