:root {
    color-scheme: light dark;
}

@view-transition {
    navigation: auto;
}

body {
    grid-template-areas: "header " "main" "footer";
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.site-header {
    grid-area: header;
}

main {
    grid-area: main;
}

.site-footer {
    grid-area: footer;
    color: var(--secondary-text);
}

.wrap {
    width: 90%;
    max-width: 70rem;
    margin-left: auto;
    margin-right: auto;
}

.grid-wrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--line);
    @media (min-width: 40rem) {
        grid-template-columns: 1fr 2fr;
    }
    @media (min-width: 50rem) {
        grid-template-columns: 1fr 3fr;
    }
}

.site-header,
.site-footer {
    padding-top: var(--line-2);
    padding-bottom: var(--line-2);
}

menu {
    li {
        display: inline-block;
        margin-right: 2ch;
    }
}

.journal-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        padding-bottom: 1ch;
        border-bottom: 1px solid var(--underline);
        margin-bottom: 1ch;
        
        @media (min-width: 45rem) {
            display: flex;
            justify-content: space-between;
            gap: var(--line);
        }

         a {
            font-family: var(--sans);
            text-decoration: none;
            margin-right: 1ch;
            @media (min-width: 45rem) {
                margin-left: 0;
            }
         }

         &:last-of-type {
            margin-bottom: 0;
            border-bottom: 0 none;
        }

        time {
            flex-shrink: 0;
            color: var(--secondary-text);
            display: inline-block;
        }
    }

}

.post-header-content {
    position: sticky;
    top: var(--line);
}

.post-content {
    max-width: 65ch;
}

hr {
    border: none;
    border-top: 1px solid var(--underline);
    margin-bottom: var(--line);
}

.post-content,
.page-content {
    img {
        margin-bottom: var(--line);
    }
}

[src$='#half'],
img.half,
.half { 
    float: left;
    width: calc(50% - calc(1ch));
    margin-right: 2ch;

  + [src$='#half']:nth-of-type(even),
  + .half:nth-of-type(even) {
    margin-right: 0;
  }
}

.library-list {
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-gap: var(--line);
    align-items: baseline;
    grid-template-columns: repeat(2,1fr);
    @media (min-width: 45rem) {
        grid-template-columns: repeat(3,1fr);
    }
    @media (min-width: 55rem) {
        grid-template-columns: repeat(4,1fr);
    }
}

.library-cover {
    margin-bottom: var(--line);
    width: 100%;
    max-width: 10rem;
    max-height: 25rem;
}

.library-cover-placeholder {
    width: 100%; 
    aspect-ratio: 1 / 1.25; 
    display: block; 
    background-color: var(--underline); 
    opacity: 0.25;
}

.site-tagline {
    color: var(--secondary-text);
    display: block;
}

.banner-image {
    margin-bottom: var(--line-2);
}

table {
    font-family: var(--sans);
    border-collapse: collapse;
    max-width: 100%;
    min-width: 50%;
}

td {
    padding: 1ch;
}

tr {
    border-bottom: 1px solid var(--underline);
}

.botdueck-embed {
    max-width: 55ch;
  }
  
  .spoilers {
    filter: blur(0.25rem);
    transition-duration: 0.5s;
    &:active,
    &:focus,
    &:hover {
      filter: blur(0);
    }
  }

.placeholder {
    background-color: var(--grey);
    opacity: 0.5;
    content: '';
    display: block;
    aspect-ratio: 1;
}

archive li a {
    text-decoration: none;
}

figure {
    margin-bottom: var(--line);
    max-width: 100%;
}

.workboard {
    // columns: 2;
    column-gap: var(--line);
    padding: var(--line);
    margin-inline: auto;
    display: grid;
    grid-gap: var(--line);
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 15em), 1fr));
    align-items: center;

    @supports(grid-template-rows: masonry) {
        grid-template-columns: repeat(auto-fill, minmax(15ch, 1fr));
        grid-template-rows: masonry;
    }
}

.workboard-item {
    margin-bottom: var(--line);
    width: 100%;
    overflow: hidden;
    position: relative;

    img {
        border-radius: 0.5ch;
        &[data-ratio] {
            aspect-ratio: attr(data-ratio integer, auto);
            &:after {
                content: attr(data-ratio string);
            }
        }
    }
    .work-caption {
        // display: none;
        position: absolute;
        background-color: var(--background);
        color: var(--body-color);
        padding: 0.5ch 1ch;
        border-radius: var(--radius, 0.5ch);
        inset-block-end: 1ch;
        inset-inline-start: 1ch;
        max-width: calc(100% - 2ch);
        transition-duration: 0.25s;
        opacity: 0;
    }
    &:hover {
        .work-caption {
            display: block;
            opacity: 1;
        }
    }

}

.sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

:target {
    scroll-margin-block: 5ex;
}

.post-content,
.page-content {
    :target {
        scroll-margin-block: var(--line);
    }
}

.feature-list {
    list-style: none;
    padding-left: 0;

    li {
        padding: 1em 0;
        border-bottom: 1px solid var(--underline);

        &:first-of-type {
            padding-top: 0;
        }
    }
    
    .emoji {
        margin-inline-end: 1ch;
    }
}

.filter-list {
    font-family: var(--sans);
    border-block-end: 1px solid var(--underline);
    padding-block-end: var(--line);
    a {
        margin-inline: 1ch;
    }
    margin-block-end: var(--line);
}

span.icons {
    position: absolute;
    top: 1ch;
    left: 1ch;
    z-index: 100;
}

.library-list li {
    position: relative;
}

@media not (prefers-reduced-motion: reduce) {
	.journal-list-title,
    .post-title { 
        view-transition-name: title
    }
}

