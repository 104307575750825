// $black : #050708;
$black: #313f48;
$black: mix(#313f48, #1e69d9, 95%);
$white: mix(#fafafa, #f7f7f0, 75%);
$white: #f9f9f5;
$grey: mix($black, $white, 60%);
$dark-grey: mix($grey, $black);
$primary: #1e69d9;
$primary: #247f5e;
$secondary: #1e69d9;
$light-grey: mix(#f0eff4, #313f48, 66%);

$base-font-size: 1.25rem;
$base-font-size: clamp(1.125rem, calc(1.1rem + 0.2vw), 1.25rem);
$base-line-height: 1.6; 
$line: 2rem;

:root {
    color-scheme: light dark;
    --black: #{$black};
    --white: #{$white};
    --light-grey: #{$light-grey};
    --dark-grey: #{$dark-grey};
    --body-color: #{$black};
    --grey: #{$grey};
    --sans: system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
    --serif: Calluna, Georgia, serif;
    --mono: ui-monospace, SFMono-Regular, monospace;
    --primary-color: #{$primary};
    --secondary-color: #{$secondary};
    --base-font-size: #{$base-font-size};
    --base-line-height: #{$base-line-height};
    --line: calc(var(--base-font-size) * var(--base-line-height));
    --line-2: calc(var(--base-font-size) * var(--base-line-height) * 2);
    --secondary-text: #{mix($black, $white, 66%)};
    --background: var(--white);
    --body-color: var(--black);
    --underline: var(--light-grey);
}

body {
    background-color: var(--background);
    color: var(--body-color);
}

@media (prefers-color-scheme: dark) {
    :root {
        --background: #{mix($black, black, 33%)};
        // --background: Canvas;
        --body-color: var(--white);
        // --body-color: CanvasText;
        --underline: var(--grey);
    }
}