.work-archive {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--line);
    align-items: baseline;
    list-style: none;
    padding: none;

    li {
        list-style: none;
        margin: 0;
        img {
            aspect-ratio: 1 / 2;
            object-fit: cover;
        }
    }
}